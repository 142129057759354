.visual {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: block;
  width: 100%;

  img {
    width: 100%;
  }

  &.visual--10x3 {
    height: 0;
    padding-top: 30%;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      object-fit: cover;
      object-position: center;
      left: 0;
      top: 130px;
      right: 0;
      bottom: 0;
      display: block;
      margin: auto;

      @media #{$maxMedium} {
        top: 30px;
      }
    }
  }
}

.visual--rounded {
  img {
    border-radius: $borderRadiusMedium;
  }
}

/*============================================================================
  FORMS
==============================================================================*/

form {
  margin: 0;

  .grid {
    margin-bottom: 0;
  }

  .button {
    margin-top: 0;
  }
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  @include fs(16);
  @include optimize-font;
  font-weight: 500;
  margin-bottom: 20px;
}

.legend--small {
  font-weight: 400;
  text-transform: uppercase;
  @include fs(13);
}

button,
input,
select,
textarea,
.button {
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
  transition: $transitionDefault;
}

.button {
  @media #{$maxMedium} {
    width: 100%;
    text-align: center;
  }
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

// When having a body font-size < 16px
// Prevent zoom on touch devices in active inputs
// @media #{$maxMedium} {
//   input, textarea {
//     font-size: 16px;
//   }
// }

label,
.label,
input,
select,
textarea {
  @include default-font();
  vertical-align: middle;
  color: $black;
}

label,
.label {
  display: block;
  margin-bottom: 8px;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
  border: none;
}

input[type="checkbox"],
input[type="radio"] {
  cursor: pointer;
}

input[type="checkbox"] {
  width: 2.1rem;
  height: 2.1rem;

  &:before {
    content: "";
    background-image: url(../../assets/images/checkmark.svg);
    background-repeat: no-repeat;
    display: flex;
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    margin-top: 0.6rem;
    margin-left: 0.4rem;
    transition: $transitionDefault;
    transform: translateY(2px);
    opacity: 0;
  }

  &:after {
    content: "";
    background-color: white;
    display: flex;
    width: 2.1rem;
    height: 2.1rem;
    border: 0.2rem solid $accent;
    border-radius: $borderRadius;
  }

  &:checked {
    &:before {
      opacity: 1;
      transform: translateY(0);
    }

    &:after {
      border-color: $accent;
    }
  }
}

input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="date"],
textarea,
select {
  padding: 4px 15px 0;
  margin-bottom: 2px;
  display: block;
  height: 50px;
  line-height: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #cbcbcb;
  @include fs(18);
  background-color: #fff;

  &:focus {
    outline: 0;
    border-color: $accent;
  }
}

input[type="email"] {
  display: block;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  width: 100%;
  height: 120px;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="radio"] {
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding: 0;
  margin: 3px 0;
  *margin-top: 0;
  *height: 13px;
  *width: 13px;
  line-height: normal;
  border: none;
}

input[type="file"] {
  background-color: #fff;
  padding: initial;
  border: initial;
  line-height: initial;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="number"] {
  font-size: 1.4rem;
  height: inherit;
  background-color: rgba(203, 203, 203, 0.2);
  padding: 1rem;
  border-radius: 0.5rem;
  border: none;
  width: 80%;

  &:focus {
    outline: none;
  }
}

select {
  appearance: none;
  background-color: #fafafa;
  background-image: url("../images/select-arrow.svg");
  background-position: center right 10px;
  background-repeat: no-repeat;
  background-size: 12px 6px;
  border-radius: 0;
  padding-right: 35px;
}

select::-ms-expand {
  display: none;
}

// floating labels
.input-group {
  position: relative;
  padding-top: 11px;
}

.input-group__label {
  position: absolute;
  top: 0;
  left: 15px;
  text-transform: uppercase;
  @include fs(10);
  font-weight: 400;
  color: #cbcbcb;
  transition: $transitionDefault;
  opacity: 0;
  transform: translateY(10px);
}

.input-group--has-value {
  .input-group__label {
    opacity: 1;
    transform: translateY(0);
  }
}

.input-group--error {
  .input-group__input {
    border-color: $error;
  }
}

.form-dark-bg {
  .checkbox label,
  .checkbox input:checked + label,
  label {
    color: #fff;
  }

  input,
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="password"],
  textarea,
  select {
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.6);
    color: #fff;
  }

  ::placeholder {
    color: #fff;
  }
}

// Buttons
button,
input[type="reset"],
input[type="submit"],
.button,
.dummy-button {
  position: relative;
  float: left;
  display: block;
  clear: both;
  transition: $transitionDefault;
  outline: 0;
  line-height: 1.2em;
  padding: 15px 34px;
  margin: 10px 0 0;
  background-color: $accent;
  text-decoration: none;
  color: #fff;
  border-radius: $borderRadius;
  font-weight: 700;
  @include fs(15);
  @include optimize-font;
  letter-spacing: 1px;

  .icon {
    margin: -3px 12px 0 -2px;
    fill: #fff;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $extraOrange;
    color: #fff;
  }

  &:hover,
  &:focus {
    transform: translateY(-3px);
  }

  &:active {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
    transform: translateY(1px);
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &.button--dark {
    background-color: $black;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: $extraOrange;
    }
  }

  &.button--xlarge {
    @include fs(16);
    padding: 20px 40px;

    @media #{$maxMedium} {
      width: 100%;
      text-align: center;
    }
  }

  &.button--small {
    padding: 10px 15px;
    @include fs(13);
  }

  &.button--x-small {
    @include fs(10);
    padding: 3px 0;
    background-color: #fff;
    color: $black;
    border-radius: 0;
    border-bottom: 1px solid #999;
    text-transform: lowercase;

    &:hover,
    &:focus {
      color: $accent;
      border-bottom-color: $accent;
    }
  }

  &.button--x-small--animated {
    border-bottom: none;

    &:after {
      content: "";
      width: 0;
      height: 1px;
      left: 0;
      bottom: 0;
      position: absolute;
      display: block;
      background: #999;
      transition: width $transitionDefault;
    }
  }

  &.button--x-small-tdn {
    border-bottom-color: transparent;
    border-radius: 0;

    &:hover,
    &:focus {
      border-bottom-color: transparent;
    }
  }

  &.button--project,
  &.button--project-light {
    background-color: transparent;
    border: 1px solid $black;
    font-weight: 500;
    font-family: $bureauSerif;
    color: $black;
    border-radius: $borderRadiusMedium * 2;

    &::before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: $accent;
      margin-right: 10px;
      transition: $transitionDefault;
    }

    &:hover,
    &:focus,
    &:active {
      border-color: $accent;
      background-color: $accent;
      color: $white;

      &::before {
        background-color: $white;
      }
    }

    @media #{$maxMedium} {
      width: auto;
    }
  }

  &.button--project-light {
    border-color: $white;
    color: $white;
  }

  @media #{$medium} {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.button__superscript {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  line-height: 13px;
  color: white;
  font-size: fs(9);
  background: $accent;
  text-align: center;
}

.button--large,
input[type="submit"].button--large {
  padding: 18px 60px;
}

.button--white,
input[type="submit"].button--white {
  background-color: #fff;
  color: $accent;

  .icon {
    fill: $accent;
  }

  &:hover,
  &:focus {
    color: $accentHover;
    background-color: #fff;
  }
}

.button--black {
  color: #fff;
  background-color: $black;

  .icon {
    fill: #fff;
  }

  &:hover,
  &:focus {
    color: #fff;
    background-color: $grayDark;
  }
}

.button--full {
  width: 100%;
  float: none;
  margin-bottom: 20px;
  text-align: left;

  .icon {
    float: right;
    margin-top: 2px;
  }

  .icon-arrow {
    width: 10px;
    height: 10px;
  }
}

.text-center .button {
  float: none;
  display: inline-block;
}

button,
input[type="text"],
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

button,
input[type="reset"],
input[type="submit"],
.button,
a.button,
select {
  &:focus {
    outline: 0;
    *outline: inherit;
    box-shadow: 0 0 1px 1px rgba(200, 200, 200, 0.1);
  }
}

::placeholder {
  color: #555;
}

// Hide clear text field ie 10+
::-ms-clear {
  display: none;
}

// Remove the rounded corners
input[type="search"] {
  -webkit-appearance: none;
}

// Hide the cancel button
::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

// Hide the magnifying glass
::-webkit-search-results-button {
  -webkit-appearance: none;
}

// Hide number input spinner
::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

// Style file upload button

// Forms
form {
  .row {
    margin-bottom: 0;
  }
}

fieldset {
  p,
  ul,
  li {
    @include clearfix;
  }

  ul {
    margin-left: -20px;

    li {
      list-style: none;
      background: none;
      list-style-image: none;
      float: left;
      width: 100%;
      padding: 0 0 0 20px;
      margin-bottom: 25px;

      &.error {
        input {
          border-color: $error;
        }
      }

      & label span {
        color: #8a919e;
      }

      &.space {
        margin-top: 25px;
      }

      @media #{$medium} {
        &.one-half {
          width: 50%;
        }
        &.one-fourth {
          width: 25%;
        }
      }
    }
  }
}

.choices {
  li {
    margin-bottom: 3px;
  }
}

.choices--columns {
  @media #{$medium} {
    ul {
      column-count: 2;
      margin-left: 0;
      column-gap: 10px;

      li {
        page-break-inside: avoid;
        padding-left: 0;
      }
    }
  }
}

.buttons {
  @include clearfix;
  button,
  input[type="reset"],
  input[type="submit"],
  .button,
  a.button {
    float: left;
    clear: none;
    margin-right: 15px;

    &.right {
      float: right;
      margin-right: 0;
      margin-left: 15px;
    }
  }

  .button-link {
    display: inline-block;
    margin-top: 25px;
  }
}

.button-group {
  display: flex;
  align-items: center;
  flex-direction: column;

  &--horizontal {
    justify-content: center;
  }
}

.button-group--start {
  align-items: flex-start;
}

.a-c {
  .button,
  a.button,
  button {
    float: none;
    clear: none;
    display: inline-block;
  }
}

/* Range input styling */
.range {
  display: flex;
  width: 100%;

  @media #{$medium} {
    max-width: 340px;
  }
}

.range-main {
  @media #{$medium} {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }
}

.range-main__item {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  padding-right: 10px;

  label {
    margin: 0 5px 0 15px;
  }

  input {
    flex: 1;
    max-width: 80%;
    background-color: #fff;
    height: 55px;
  }

  + .range-main__item {
    border-left: 1px solid $grayLight;
  }

  @media #{$medium} {
    flex: 1;
    margin-bottom: 0;
  }
}

.range-btn {
  margin: 0;
  padding: 0;
  background-color: $accent;
  width: 42px;
  height: 42px;

  .icon {
    position: relative;
    top: -1px;
    transition: $transitionDefault;
    margin: 0;
  }

  &:hover,
  &:focus {
    transform: none;

    .icon {
      transform: translateX(2px);
    }
  }
}

/* switch button / radio input */
.switch {
  margin-bottom: 17px;
  display: flex;
}

.switch__btn {
  cursor: pointer;
  margin: 0;
  height: 30px;
  text-align: center;
  background-color: #f7f7f7;
  color: #666;
  text-decoration: none;
  @include fs(13);
  padding: 0 5px;
  line-height: 30px;
  width: calc(50% - 5px);
  transition: $transitionDefault;
  text-transform: uppercase;
  margin-right: 10px;

  .icon {
    fill: #666;
    margin-right: 10px;
  }

  + .switch__btn {
    border-left: 1px solid #000;
  }

  &:last-of-type {
    margin-right: 0;
  }

  @media #{$medium} {
    width: calc(50% - 20px);
    margin-right: 40px;
  }
}

.switch__btn:hover,
.switch__btn:focus,
.switch__btn--active,
.switch__input:checked + .switch__btn {
  color: #fff;
  background-color: $extraOrange;

  .icon {
    fill: #fff;
  }
}

.switch__btn--active,
.switch__input:checked + .switch__btn {
  font-weight: 600;
}

.switch__btn--small {
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 0;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }
}

.switch__btn--large {
  height: 58px;
  line-height: 58px;
}

.switch__input {
  display: none;
}

/* default checkbox */
.checkbox {
  position: relative;

  input[type="checkbox"] {
    min-width: 21px;
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
    z-index: -1;
  }

  input:checked + label {
    color: #000;

    &:after {
      opacity: 1;
      transform: translateY(0);
    }

    &:before {
      border-color: $black;
    }
  }

  label {
    position: relative;
    cursor: pointer;
    @include fs(16);
    color: #666;
    max-width: 100%;
    word-break: break-word;
    hyphens: auto;
    padding-left: 35px;

    small {
      color: #999;
      @include fs(12);
    }

    &:before,
    &:after {
      content: "";
      display: block;
      transition: $transitionDefault;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:before {
      content: "";
      background-color: white;
      width: 21px;
      height: 21px;
      border: 1px solid $gray;
      border-radius: $borderRadius;
      margin-right: 1.5rem;
    }

    &:after {
      background-image: url(../../assets/images/checkmark.svg);
      background-repeat: no-repeat;
      width: 12px;
      height: 12px;
      margin-top: 6px;
      margin-left: 4px;
      transform: translateY(2px);
      opacity: 0;
    }
  }
}

.checkbox--disabled {
  opacity: 0.7;
  pointer-events: none;
}

.checkbox--dark {
  label {
    color: $black !important;
  }
}

/* checkbox button */
.checkbox-button {
  @include clearfix;
  margin-bottom: 20px;
}

.checkbox-button__input {
  display: none;

  &:checked + .checkbox-button__label {
    background-color: #fff;
    color: $accent;

    .icon {
      fill: $accent;
      opacity: 1;
    }
  }
}

.checkbox-button__label {
  @extend .button;
  padding: 22px 18px;
  cursor: pointer;
  margin: 0;

  .icon {
    opacity: 0.5;
  }

  @media #{$medium} {
    padding-left: 30px;
    padding-right: 30px;
  }
}

// #radio-map {
//   // padding: 1.3rem 3rem;

//   & .switch-icon {
//     // position: absolute;
//     // margin-left: -1.5rem;
//     // margin-top: -0.2rem;
//     // width: 2.5rem;
//     // height: 2.5rem;
//   }
// }

// #radio-img {
//   // padding: 1.3rem 3rem;

//   & .switch-icon {
//     // position: absolute;
//     // margin-left: -1.2rem;
//     // margin-top: .1rem;
//     // width: 2rem;
//     // height: 2rem;
//   }
// }

// Recaptcha
.grecaptcha-badge {
  display: none;
}

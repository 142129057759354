.plyr--full-ui.plyr--video .plyr__control--overlaid {
  transform: translate(-50%, -50%);
}

.plyr__controls {
  @media #{$large} {
    padding-right: 350px !important;
  }

  @media #{$larger} {
    padding-right: 200px !important;
  }

  @media #{$massive} {
    padding-right: 150px !important;
  }
}

.plyr__control {
  margin-top: 0;
}

.landscape-slideshow__slide-video {
  min-width: 100vw;

  @media #{$medium} {
    min-width: 620px;
  }

  @media #{$large} {
    min-width: 960px;
  }

  @media #{$larger} {
    min-width: 1067px;
  }
}

.ytp-chrome-top {
  display: none !important;
}

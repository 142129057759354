@import "aos/core";
@import "aos/easing";
@import "aos/animations";

.aos-animate {
  .button--x-small--animated {
    &:after {
      width: 100%;
      transition: width $transitionDefault;
    }
  }
}

[data-aos="jumbotron"] {
  @media #{$medium} {
    &.jumbotron {
      .jumbotron__content {
        opacity: 1;
        transform: translateX(-100%);
        transition-duration: 800ms;
        transition-property: transform, opacity;

        &:before {
          transform: scale(0);
          transition-property: all;
          transform-origin: left center;
          transition-duration: 600ms;
        }

        &:after {
          transform: scale(0);
          transition-property: all;
          transform-origin: left center;
          transition-duration: 600ms;
        }

        .grid {
          opacity: 0;
          transition: opacity $transitionDefault;
          transition-delay: 300ms;
        }
      }

      .masthead-home__visual {
        width: 100%;
        transition-duration: 800ms;
      }
    }
  }

  &.aos-animate {
    @media #{$medium} {
      &.jumbotron {
        .jumbotron__content {
          opacity: 1;
          transform: translateX(0%);
          transition-duration: 800ms;
          transition-property: transform, opacity;

          &:before {
            transform: scale(1);
            transition-property: all;
            transform-origin: left center;
            transition-duration: 600ms;
          }

          &:after {
            transform: scale(1);
            transition-property: all;
            transform-origin: left center;
            transition-duration: 600ms;
          }

          .grid {
            opacity: 1;
            transition: opacity $transitionDefault;
            transition-delay: 300ms;
          }
        }

        .masthead-home__visual {
          width: 80%;
          transition-delay: 300ms;
          transition-duration: 500ms;
          transition-property: width;
        }
      }
    }
  }
}

.account-tab__section {
  margin-bottom: $gutter;

  @media #{$medium} {
    margin-bottom: $gutter * 2;
  }
}

.account-tab__section-title {
  font-size: rem(28px);

  .icon {
    margin-right: 10px;
  }
}

.account-tab__chart-legend {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  font-size: rem(14px);
  margin: 20px 0 0;

  li + li {
    margin-left: 40px;
    margin-bottom: 10px;
  }
}

.account-tab__chart-legend-color {
  display: inline-block;
  width: 40px;
  height: 2px;
  background-color: #aaa;
  margin-right: 10px;
  vertical-align: middle;
}

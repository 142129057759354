.select {
  z-index: 9999;
}

.select-select {
  display: none;
  /* For when we are on a small touch device and want to use native controls */
  pointer-events: none;
  position: absolute;
  opacity: 0; 
}

.select-element, .select-element:after, .select-element:before, .select-element *, .select-element *:after, .select-element *:before {
  box-sizing: border-box; }

.select-element {
  position: absolute;
  display: none; }
  .select-element.select-open {
    display: block; }

.select-theme-default, .select-theme-default *, .select-theme-default *:after, .select-theme-default *:before {
  box-sizing: border-box; }

.select.select-theme-default {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; 
}

  .select.select-theme-default .select-content {
    border-radius: .25em;
    box-shadow: 0 35px 100px rgba(0, 0, 0, 0.1);
    background: white;
    font-family: inherit;
    color: black;
    overflow: auto;
    max-width: 18rem;
    max-height: 18rem;
    -webkit-overflow-scrolling: touch; 
  }
    @media (max-width: 27rem), (max-height: 27rem) {
      .select.select-theme-default .select-content {
        max-width: 11.25rem;
        max-height: 11.25rem; 
      } 
    }

  .select.select-theme-default .select-options {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    margin: 0;
    padding: 0; }
    .select.select-theme-default .select-options .select-option {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      position: relative;
      list-style: none;
      margin: 0;
      line-height: 1.25rem;
      padding: 1rem 1em 1rem 2.5em;
      display: block;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; 
    }
      .select.select-theme-default .select-options .select-option.select-option-selected:before {
        content: url("data:image/svg+xml;utf8,<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 11.7 8.3' style='enable-background:new 0 0 11.7 8.3;' xml:space='preserve'><g id='Path_241'><path fill='#000' d='M4.4,8.3L4.4,8.3C4.1,8.3,3.9,8.2,3.7,8L0.3,4.6c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2.7,2.7L10,0.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L5.1,8C4.9,8.2,4.7,8.3,4.4,8.3z'/></g></svg>"); 
        position: absolute;
        left: 1em;
        top: 0;
        bottom: .2em;
        height: 1em;
        width: 1em;
        margin: auto; 
        margin-top: 1rem;
      }
      .select.select-theme-default .select-options .select-option:hover, .select.select-theme-default .select-options .select-option.select-option-highlight {
        background: $grayLight;
        color: $accent; 
      }
        .select.select-theme-default .select-options .select-option:hover.select-option-selected:before, .select.select-theme-default .select-options .select-option.select-option-highlight.select-option-selected:before {
          content: url("data:image/svg+xml;utf8,<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 11.7 8.3' style='enable-background:new 0 0 11.7 8.3;' xml:space='preserve'><g id='Path_241'><path fill='#023F85' d='M4.4,8.3L4.4,8.3C4.1,8.3,3.9,8.2,3.7,8L0.3,4.6c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2.7,2.7L10,0.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L5.1,8C4.9,8.2,4.7,8.3,4.4,8.3z'/></g></svg>"); 
          margin-top: 1rem;
        }
      .select.select-theme-default .select-options .select-option:first-child {
        border-radius: 0.25em 0.25em 0 0; }
      .select.select-theme-default .select-options .select-option:last-child {
        border-radius: 0 0 0.25em 0.25em; }

.select-target.select-theme-default {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  border-radius: .25em;
  position: relative;
  padding: 0.5rem 3em 0.5rem 1em;
  background: transparent;
  cursor: pointer;
  color: #444;
  text-decoration: none;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; 
  width: 20rem;
}
  .select-target.select-theme-default:hover {
    border-color: #aaa;
    color: #000; }
  .select-target.select-theme-default.select-target-focused, .select-target.select-theme-default.select-target-focused:focus {
    border-color: #63a2f1;
    outline: none; }
  .select-target.select-theme-default b {
    position: absolute;
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10.9 6.9' style='enable-background:new 0 0 10.9 6.9;' xml:space='preserve'><g id='Path_201'><polygon fill='#023F85' points='5.5,6.9 0,1.4 1.4,0 5.5,4 9.5,0 10.9,1.4'/></g></svg>"); 
    background-repeat: no-repeat;
    top: 10%;
    bottom: 0;
    margin: auto;
    height: 1.25rem;
    width: 1.5rem; 
    right: 15%;
  }
  //  .select-target.select-theme-default b:after {
  //     content: url("data:image/svg+xml;utf8,<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 11.7 8.3' style='enable-background:new 0 0 11.7 8.3;' xml:space='preserve'><g id='Path_241'><path fill='#023F85' d='M4.4,8.3L4.4,8.3C4.1,8.3,3.9,8.2,3.7,8L0.3,4.6c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2.7,2.7L10,0.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L5.1,8C4.9,8.2,4.7,8.3,4.4,8.3z'/></g></svg>"); 
  //     display: block;
  //     position: absolute;
  //     margin: auto;
  //     right: 0;
  //     height: 0;
  //     width: 0;
  //     border: .263em solid transparent; 
  //   }

  //   .select-target.select-theme-default b:after {
  //     bottom: 0;
  //     border-top-color: inherit; }


/*============================================================================
  TABLES
==============================================================================*/

table {
  width: 100%; padding: 0; border-collapse: separate; border-spacing: 0;
  empty-cells: show; margin: 0 0 1.5em;

  th, td {
    padding: 11px 6px; text-align: left; vertical-align: middle;
    border-bottom: 1px solid #ddd; line-height: 1.5em;
  }

  th {
    font-weight: bold; border-bottom: 2px solid #ddd;
  }

  .inactive {
    color: #ccc;
  }
}

.table-responsive {
  @media #{$maxMedium} {
    thead {
      display: none;
    }

    tr {
      display: block;
      margin-bottom: 20px;
    }

    tr, td {
      float: left;
      clear: both;
      width: 100%;
    }

    th, td {
      display: block;
      text-align: left;
      padding: 15px;
    }

    td:before {
      content: attr(data-label);
      float: left;
      padding-right: 10px;
      min-width: 70px;
      font-weight: 600;
    }

  }
}

.account-card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 25px rgba($color: #000000, $alpha: 0.05);
  overflow: hidden;
}

.account-card--has-visual {
  @media #{$medium} {
    display: flex;
    align-items: center;
  }
}

.account-card__visual {
  //@extend .teammate-cta__visual;
  position: static;
  width: 150px;
  height: 180px;
}

.account-card__header {
  background-color: #f7f7f7;
  text-align: center;
  padding: 18px 0;
}

.account-card__header-title {
  color: $black;
  font-size: rem(16px);
  margin-bottom: 0;
}

.account-card__content {
  padding: 20px;
  font-size: em(16px);

  @media #{$medium} {
    padding: 20px 40px;
  }
}

.account-card__footer {
  background-color: $black;
  color: $white;
  padding: 20px;

  a {
    color: $white;
    text-decoration: none;

    &:hover,
    &:focus {
      color: rgba($color: #fff, $alpha: 0.9);
    }
  }

  svg {
    fill: $white;
    margin-right: 20px;
  }

  @media #{$medium} {
    padding: 20px 40px;
    margin-top: 30px;
  }
}

.account-card__chart--small {
  width: 120px;
  position: relative;
  margin: 0 auto;
  pointer-events: none;
}

.account-card__checklist {
  list-style: none;
  margin: 0;
}

.account-card__checklist-item {
  border-bottom: 1px solid rgba($color: #707070, $alpha: 0.25);
  padding: 8px 0;

  &:before {
    content: "";
    display: inline-block;
    width: 40px;
  }
}

.account-card__checklist-item--checked {
  &:before {
    content: "✔";
  }
}

.account-card__checklist-item--unchecked {
  color: $grayMiddle;
}

.account-card__checklist-item-info {
  color: $grayDark;
  font-size: 14px;
}

.account-card__activities-table {
  @media #{$medium} {
    table-layout: fixed;
  }
}

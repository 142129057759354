/*============================================================================
  TYPOGRAPHY
==============================================================================*/

// Local fonts
@font-face {
  font-family: "bureau-sans";
  src:
    url("../fonts/bureau-sans/STKBureauSans-Light.woff2") format("woff2"),
    url("../fonts/bureau-sans/STKBureauSans-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "bureau-sans";
  src:
    url("../fonts/bureau-sans/STKBureauSans-Book.woff2") format("woff2"),
    url("../fonts/bureau-sans/STKBureauSans-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "bureau-sans";
  src:
    url("../fonts/bureau-sans/STKBureauSans-Italic.woff2") format("woff2"),
    url("../fonts/bureau-sans/STKBureauSans-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "bureau-sans";
  src:
    url("../fonts/bureau-sans/STKBureauSans-Bold.woff2") format("woff2"),
    url("../fonts/bureau-sans/STKBureauSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "bureau-serif";
  src:
    url("../fonts/bureau-serif/STKBureauSerif-Medium.woff2") format("woff2"),
    url("../fonts/bureau-serif/STKBureauSerif-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// Font Stacks
@mixin default-font($size: 16, $weight: 400, $lineHeight: 1.5em) {
  font-family: $defaultFont;
  @include fs($size);
  line-height: $lineHeight;
  font-weight: $weight;
}

@mixin display-font() {
  font-weight: 500;
}

// Body

p {
  line-height: 1.5em;
  margin-bottom: 1.5em;
}

strong {
  font-weight: 700;
  font-size: inherit;
}

em {
  font-style: italic;
  font-weight: inherit;
}

a {
  font-weight: inherit;
  font-style: inherit;
  color: $linkColor;
  transition: $transitionDefault;
  text-decoration: underline;
  text-decoration-skip: ink;
  // @include smart-underline; cross browser text-decoration skip

  &:hover,
  &:focus {
    color: $linkColorHover;
  }

  &:focus,
  &:active {
    outline: 0;
  }

  &.tdn {
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

a:not([href]) {
  color: $accent;
  text-decoration: none;
  &:hover,
  &:focus {
    color: $accent;
  }
}

.accent-color {
  color: $accent !important;
}

.text-lined {
  display: inline-block;
  position: relative;
  font-weight: 400;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0.4em;
    left: 0;
    right: 0;
    border-bottom: 1px solid $accent;
  }
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}

.img-rounded {
  overflow: hidden;
  border-radius: $borderRadius;

  @media #{$medium} {
    float: left;
  }
}

.img--full {
  width: 100%;
}

// Headings

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h1-light {
  @include display-font;
  @include optimize-font;
  color: $accent;
  line-height: 1em;
  margin: 0 0 0.8em;

  a {
    text-decoration: none;
    outline: 0;
    font-weight: inherit;
    color: $accent;

    &:hover,
    &:focus {
      color: $accentHover;
    }
  }

  .text-lined {
    &:after {
      bottom: 0;
    }
  }
}

h1,
.h1 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 28px, 48px);
  font-weight: 800;
}

.w-medium {
  font-weight: 500;
}

.h1-light {
  @include fluid-type(font-size, $mediumSize, $largerSize, 24px, 32px);
  color: $black;
}

.h1-small {
  @include fluid-type(font-size, $mediumSize, $largerSize, 20px, 28px);
  color: $black;
  font-weight: 500;
  margin-bottom: 1.2em;
}

h2,
.h2 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 24px, 32px);
  font-weight: 800;

  &.h2--medium-light {
    @media #{$medium} {
      color: $white;
    }
  }
  &.h2--orange {
    color: $extraOrange;
  }
}

h3,
.h3 {
  @include fs(24);
  font-weight: 600;

  &.h3--orange {
    color: $extraOrange;
  }
}

.h2--dark,
.h3--dark {
  color: $black;
}

h4,
.h4 {
  @include fs(18);
}

.accent {
  color: $accent;
}

.black {
  color: $black;
}

// Lists

ul,
ol {
  margin: 0 0 1.5em 16px;

  li {
    line-height: 1.5em;
    padding: 0;
    margin-bottom: 0.5em;
  }
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;

  ul {
    list-style: circle;
  }

  &.lined {
    @extend .unstyled;
    li {
      border-bottom: 1px solid #e3e3e3;
      padding: 5px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

ol {
  list-style: decimal;
}

.unstyled,
nav ul {
  margin: 0 0 1.5em;
  padding: 0;
  list-style: none;

  ul {
    list-style: none;
  }

  li {
    list-style: none;
    list-style-image: none;
    padding: 0;
    background: none;
  }
}

nav ul {
  @include clearfix;
}

dl {
  margin: 0 0 1.5em;
}

dt,
dd {
  line-height: 1.5em;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5em;
}

.dl-lined {
  margin: 0 0 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;

  dt,
  dd {
    float: left;
    width: 50%;
    padding: 10px 0;
  }

  dt {
    float: left;
    @include fs(14);
    clear: left;
    padding-top: 10px;
    font-weight: 400;
  }

  dd {
    text-align: right;
    padding-top: 4px;
  }
}

.icons-list {
  list-style: none;
  margin-left: 0;

  li {
    position: relative;
    padding-left: 35px;
  }

  .icon {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.icon-link {
  text-decoration: none;

  .icon {
    margin-right: 8px;
  }
}

.button-list {
  display: flex;
  list-style: none;
  margin: 0 0 2em;
  padding: 0;

  li + li {
    margin-left: 10px;
    margin-bottom: 15px;
  }

  .button {
    min-height: 38px;

    .icon {
      margin-right: 6px;
    }
  }
}

// Misc

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e2e2e2;
  margin: 20px 0 50px;
}

blockquote {
  line-height: inherit;
  padding-left: 20px;
  border-left: 1px solid $gray;
  margin-bottom: 1.5em;
  @include fs(20);
  font-style: italic;

  p {
    margin-bottom: 0.5em;
  }

  cite {
    @include fs(14);
  }

  @media #{$medium} {
    padding-left: 40px;
  }
}

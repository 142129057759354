
/*============================================================================
#Video.js
==============================================================================*/
.video-js .vjs-big-play-button:before, .video-js .vjs-control:before, .video-js .vjs-modal-dialog, .vjs-modal-dialog .vjs-modal-dialog-content {
	position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}

.video-js .vjs-big-play-button:before, .video-js .vjs-control:before {
	text-align: center;
}

video {
	background: #fff!important;

	&.video-js {
		visibility: hidden;
	}
}

.video-js {
	display: block; vertical-align: top; box-sizing: border-box;
	color: #fff; background: transparent center center no-repeat; background-size: cover;
	position: relative; padding: 0; font-size: 10px;
	line-height: 1; font-weight: normal; font-style: normal;
	-webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;

	&:-moz-full-screen {
		position: absolute;
	}

	&:-webkit-full-screen {
		width: 100% !important;
		height: 100% !important;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	ul {
		margin: 0;
	}
}

.video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-14-9, .video-js.vjs-4-3 {
	width: 100%; max-width: 100%; height: 0;
}

.video-js.vjs-16-9 {
	padding-top: 56.25%;
}

.video-js.vjs-14-9 {
	padding-top: 64.17%;
	backface-visibility: hidden;
}

.video-js.vjs-4-3 {
	padding-top: 75%;
}

.video-js.vjs-fill {
	width: 100%; height: 100%;
}

.video-js .vjs-tech {
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-js.vjs-playing .vjs-tech {
	pointer-events: none;
}

body.vjs-full-window {
	padding: 0;
	margin: 0;
	height: 100%;
	overflow-y: auto; }

.vjs-full-window .video-js.vjs-fullscreen {
	position: fixed;
	overflow: hidden;
	z-index: 1000;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0; }

.video-js.vjs-fullscreen {
	width: 100% !important;
	height: 100% !important;
	padding-top: 0 !important; }

.video-js.vjs-fullscreen.vjs-user-inactive {
	cursor: none; }

.vjs-hidden {
	display: none !important; }

.vjs-disabled {
	opacity: 0.5;
	cursor: default; }

.video-js .vjs-offscreen {
	height: 1px;
	left: -9999px;
	position: absolute;
	top: 0;
	width: 1px; }

.vjs-lock-showing {
	display: block !important;
	opacity: 1;
	visibility: visible; }

.vjs-no-js {
	padding: 20px;
	color: #fff;
	background-color: #000;
	font-size: 18px;
	font-family: $defaultFont;
	text-align: center;
	width: 300px;
	height: 150px;
	margin: 0px auto; }

.vjs-no-js a,
.vjs-no-js a:visited {
	color: #66A8CC; }

.video-js .vjs-big-play-button {
	display: block; position: absolute; top: 50%; left: 50%; z-index: 10000;
	transform: translate(-50% , -50%);
	cursor: pointer; opacity: 0.75; background: 0;
	transition: 0.3s;

	&:after {
		content: ""; display: block; width: 0; height: 0;
		border: 30px solid transparent; border-right: 0; border-left: 47px solid #fff;
	}

	&:hover, &:focus {
		opacity: 1;
	}

  @media #{$medium} {
    transform: translateX(-50%) translateY(-50%) scale(1.25);
  }
}

.autoplay:not(.video-object) {
	@media #{$medium} {
		.vjs-big-play-button {
			display: none;
		}

		video {
			visibility: hidden;
		}

		.vjs-poster {
			display: none;
		}

		&.vjs-playing, &.vjs-has-started.vjs-paused {
			video {
				visibility: visible;
			}
		}
	}
}

video.autoplay {
	@media #{$medium} {
		visibility: hidden;
	}
}

/*
.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
	opacity: 0;
	pointer-events: none;
	width: 5px;
}*/

.vjs-controls-disabled .vjs-big-play-button, .vjs-has-started .vjs-big-play-button,
.vjs-using-native-controls .vjs-big-play-button, .vjs-error .vjs-big-play-button {
	display: none;
}

.vjs-has-started.vjs-paused .vjs-big-play-button {
	display: block;
}

.video-js button {
	background: none;
	border: none;
	color: inherit;
	display: inline-block;
	overflow: visible;
	font-size: inherit;
	line-height: inherit;
	text-transform: none;
	text-decoration: none;
	transition: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.video-js .vjs-control.vjs-close-button {
	cursor: pointer;
	height: 3em;
	position: absolute;
	right: 0;
	top: 0.5em;
	z-index: 2; }

.vjs-menu-button {
	cursor: pointer; }

.vjs-menu-button.vjs-disabled {
	cursor: default; }

.vjs-workinghover .vjs-menu-button.vjs-disabled:hover .vjs-menu {
	display: none; }

.vjs-menu .vjs-menu-content {
	display: block;
	padding: 0;
	margin: 0;
	overflow: auto;
	font-family: $defaultFont;
}

.vjs-scrubbing .vjs-menu-button:hover .vjs-menu {
	display: none; }

.vjs-menu li {
	list-style: none;
	margin: 0;
	padding: 0.2em 0;
	line-height: 1.4em;
	font-size: 1.2em;
	text-align: center;
	text-transform: lowercase; }

.vjs-menu li:focus,
.vjs-menu li:hover {
	outline: 0;
	background-color: #73859f;
	background-color: rgba(115, 133, 159, 0.5); }

.vjs-menu li.vjs-selected,
.vjs-menu li.vjs-selected:focus,
.vjs-menu li.vjs-selected:hover {
	background-color: #fff;
	color: #2B333F; }

.vjs-menu li.vjs-menu-title {
	text-align: center;
	text-transform: uppercase;
	font-size: 1em;
	line-height: 2em;
	padding: 0;
	margin: 0 0 0.3em 0;
	font-weight: bold;
	cursor: default; }

.vjs-menu-button-popup .vjs-menu {
	display: none;
	position: absolute;
	bottom: 0;
	width: 10em;
	left: -3em;
	height: 0em;
	margin-bottom: 1.5em;
	border-top-color: rgba(43, 51, 63, 0.7); }

.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
	background-color: #2B333F;
	background-color: rgba(43, 51, 63, 0.7);
	position: absolute;
	width: 100%;
	bottom: 1.5em;
	max-height: 15em; }

.vjs-workinghover .vjs-menu-button-popup:hover .vjs-menu,
.vjs-menu-button-popup .vjs-menu.vjs-lock-showing {
	display: block; }

.video-js .vjs-menu-button-inline {
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s;
	overflow: hidden; }

.video-js .vjs-menu-button-inline:before {
	width: 2.222222222em; }

.video-js .vjs-menu-button-inline:hover,
.video-js .vjs-menu-button-inline:focus,
.video-js .vjs-menu-button-inline.vjs-slider-active,
.video-js.vjs-no-flex .vjs-menu-button-inline {
	width: 12em; }

.video-js .vjs-menu-button-inline.vjs-slider-active {
	-webkit-transition: none;
	-moz-transition: none;
	-o-transition: none;
	transition: none; }

.vjs-menu-button-inline .vjs-menu {
	opacity: 0;
	height: 100%;
	width: auto;
	position: absolute;
	left: 4em;
	top: 0;
	padding: 0;
	margin: 0;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	-o-transition: all 0.4s;
	transition: all 0.4s; }

.vjs-menu-button-inline:hover .vjs-menu,
.vjs-menu-button-inline:focus .vjs-menu,
.vjs-menu-button-inline.vjs-slider-active .vjs-menu {
	display: block;
	opacity: 1; }

.vjs-no-flex .vjs-menu-button-inline .vjs-menu {
	display: block;
	opacity: 1;
	position: relative;
	width: auto; }

.vjs-no-flex .vjs-menu-button-inline:hover .vjs-menu,
.vjs-no-flex .vjs-menu-button-inline:focus .vjs-menu,
.vjs-no-flex .vjs-menu-button-inline.vjs-slider-active .vjs-menu {
	width: auto; }

.vjs-menu-button-inline .vjs-menu-content {
	width: auto;
	height: 100%;
	margin: 0;
	overflow: hidden; }

.video-js .vjs-control-bar {
	display: none!important;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 3.0em;
	background-color: #2B333F;
	background-color: rgba(43, 51, 63, 0.7); }

.vjs-has-started .vjs-control-bar {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	visibility: visible;
	opacity: 1;
	-webkit-transition: visibility 0.1s, opacity 0.1s;
	-moz-transition: visibility 0.1s, opacity 0.1s;
	-o-transition: visibility 0.1s, opacity 0.1s;
	transition: visibility 0.1s, opacity 0.1s; }

.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
	visibility: visible;
	opacity: 0;
	-webkit-transition: visibility 1s, opacity 1s;
	-moz-transition: visibility 1s, opacity 1s;
	-o-transition: visibility 1s, opacity 1s;
	transition: visibility 1s, opacity 1s; }

html.lt-ie9 {
	.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
		visibility: hidden;
	}
}

.vjs-controls-disabled .vjs-control-bar,
.vjs-using-native-controls .vjs-control-bar,
.vjs-error .vjs-control-bar {
	display: none !important; }

.vjs-audio.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
	opacity: 1;
	visibility: visible; }

html.lt-ie9 {
	.vjs-user-inactive.vjs-playing .vjs-control-bar :before {
		content: ""; } }

.vjs-has-started.vjs-no-flex .vjs-control-bar {
	display: table; }

.video-js .vjs-control {
	outline: none;
	position: relative;
	text-align: center;
	margin: 0;
	padding: 0;
	height: 100%;
	width: 4em;
	-webkit-box-flex: none;
	-moz-box-flex: none;
	-webkit-flex: none;
	-ms-flex: none;
	flex: none; }
.video-js .vjs-control:before {
	font-size: 1.8em;
	line-height: 1.67; }

.video-js .vjs-control:focus:before,
.video-js .vjs-control:hover:before,
.video-js .vjs-control:focus {
	text-shadow: 0em 0em 1em white; }

.video-js .vjs-control-text {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px; }

.vjs-no-flex .vjs-control {
	display: table-cell;
	vertical-align: middle; }

.video-js .vjs-custom-control-spacer {
	display: none; }

.video-js .vjs-progress-control {
	-webkit-box-flex: auto;
	-moz-box-flex: auto;
	-webkit-flex: auto;
	-ms-flex: auto;
	flex: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	min-width: 4em; }

.vjs-live .vjs-progress-control {
	display: none; }

.video-js .vjs-progress-holder {
	-webkit-box-flex: auto;
	-moz-box-flex: auto;
	-webkit-flex: auto;
	-ms-flex: auto;
	flex: auto;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-o-transition: all 0.2s;
	transition: all 0.2s;
	height: 0.3em; }

.video-js .vjs-progress-control:hover .vjs-progress-holder {
	font-size: 1.666666666666666666em; }

/* If we let the font size grow as much as everything else, the current time tooltip ends up
ginormous. If you'd like to enable the current time tooltip all the time, this should be disabled
to avoid a weird hitch when you roll off the hover. */
.video-js .vjs-progress-control:hover .vjs-time-tooltip,
.video-js .vjs-progress-control:hover .vjs-mouse-display:after,
.video-js .vjs-progress-control:hover .vjs-play-progress:after {
	font-family: $defaultFont;
	visibility: visible;
	font-size: 0.6em; }

.video-js .vjs-progress-holder .vjs-play-progress,
.video-js .vjs-progress-holder .vjs-load-progress,
.video-js .vjs-progress-holder .vjs-tooltip-progress-bar,
.video-js .vjs-progress-holder .vjs-load-progress div {
	position: absolute;
	display: block;
	height: 0.3em;
	margin: 0;
	padding: 0;
	width: 0;
	left: 0;
	top: 0; }

.video-js .vjs-mouse-display:before {
	display: none; }

.video-js .vjs-play-progress {
	background-color: #fff; }
.video-js .vjs-play-progress:before {
	position: absolute;
	top: -0.333333333333333em;
	right: -0.5em;
	font-size: 0.9em; }

.video-js .vjs-time-tooltip,
.video-js .vjs-mouse-display:after,
.video-js .vjs-play-progress:after {
	visibility: hidden;
	pointer-events: none;
	position: absolute;
	top: -3.4em;
	right: -1.9em;
	font-size: 0.9em;
	color: #000;
	content: attr(data-current-time);
	padding: 6px 8px 8px 8px;
	background-color: #fff;
	background-color: rgba(255, 255, 255, 0.8);
	-webkit-border-radius: 0.3em;
	-moz-border-radius: 0.3em;
	border-radius: 0.3em; }

.video-js .vjs-time-tooltip,
.video-js .vjs-play-progress:before,
.video-js .vjs-play-progress:after {
	z-index: 1; }

.video-js .vjs-progress-control .vjs-keep-tooltips-inside:after {
	display: none; }

.video-js .vjs-load-progress {
	background: #bfc7d3;
	background: rgba(115, 133, 159, 0.5); }

.video-js .vjs-load-progress div {
	background: white;
	background: rgba(115, 133, 159, 0.75); }

.video-js.vjs-no-flex .vjs-progress-control {
	width: auto; }

.video-js .vjs-time-tooltip {
	display: inline-block;
	height: 2.4em;
	position: relative;
	float: right;
	right: -1.9em; }

.vjs-tooltip-progress-bar {
	visibility: hidden; }

.video-js .vjs-progress-control .vjs-mouse-display {
	display: none;
	position: absolute;
	width: 1px;
	height: 100%;
	background-color: #000;
	z-index: 1; }

.vjs-no-flex .vjs-progress-control .vjs-mouse-display {
	z-index: 0; }

.video-js .vjs-progress-control:hover .vjs-mouse-display {
	display: block; }

.video-js.vjs-user-inactive .vjs-progress-control .vjs-mouse-display,
.video-js.vjs-user-inactive .vjs-progress-control .vjs-mouse-display:after {
	visibility: hidden;
	opacity: 0;
	-webkit-transition: visibility 1s, opacity 1s;
	-moz-transition: visibility 1s, opacity 1s;
	-o-transition: visibility 1s, opacity 1s;
	transition: visibility 1s, opacity 1s; }

.video-js.vjs-user-inactive.vjs-no-flex .vjs-progress-control .vjs-mouse-display,
.video-js.vjs-user-inactive.vjs-no-flex .vjs-progress-control .vjs-mouse-display:after {
	display: none; }

.vjs-mouse-display .vjs-time-tooltip,
.video-js .vjs-progress-control .vjs-mouse-display:after {
	color: #fff;
	background-color: #000;
	background-color: rgba(0, 0, 0, 0.8); }

.video-js .vjs-slider {
	outline: 0;
	position: relative;
	cursor: pointer;
	padding: 0;
	margin: 0 0.45em 0 0.45em;
	background-color: #73859f;
	background-color: rgba(115, 133, 159, 0.5); }

.video-js .vjs-slider:focus {
	text-shadow: 0em 0em 1em white;
	-webkit-box-shadow: 0 0 1em #fff;
	-moz-box-shadow: 0 0 1em #fff;
	box-shadow: 0 0 1em #fff; }

.video-js .vjs-mute-control,
.video-js .vjs-volume-menu-button {
	cursor: pointer;
	-webkit-box-flex: none;
	-moz-box-flex: none;
	-webkit-flex: none;
	-ms-flex: none;
	flex: none; }

.video-js .vjs-volume-control {
	width: 5em;
	-webkit-box-flex: none;
	-moz-box-flex: none;
	-webkit-flex: none;
	-ms-flex: none;
	flex: none;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center; }

.video-js .vjs-volume-bar {
	margin: 1.35em 0.45em; }

.vjs-volume-bar.vjs-slider-horizontal {
	width: 5em;
	height: 0.3em; }

.vjs-volume-bar.vjs-slider-vertical {
	width: 0.3em;
	height: 5em;
	margin: 1.35em auto; }

.video-js .vjs-volume-level {
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: #fff; }
.video-js .vjs-volume-level:before {
	position: absolute;
	font-size: 0.9em; }

.vjs-slider-vertical .vjs-volume-level {
	width: 0.3em; }
.vjs-slider-vertical .vjs-volume-level:before {
	top: -0.5em;
	left: -0.3em; }

.vjs-slider-horizontal .vjs-volume-level {
	height: 0.3em; }
.vjs-slider-horizontal .vjs-volume-level:before {
	top: -0.3em;
	right: -0.5em; }

.vjs-volume-bar.vjs-slider-vertical .vjs-volume-level {
	height: 100%; }

.vjs-volume-bar.vjs-slider-horizontal .vjs-volume-level {
	width: 100%; }

.vjs-menu-button-popup.vjs-volume-menu-button .vjs-menu {
	display: block;
	width: 0;
	height: 0;
	border-top-color: transparent; }

.vjs-menu-button-popup.vjs-volume-menu-button-vertical .vjs-menu {
	left: 0.5em;
	height: 8em; }

.vjs-menu-button-popup.vjs-volume-menu-button-horizontal .vjs-menu {
	left: -2em; }

.vjs-menu-button-popup.vjs-volume-menu-button .vjs-menu-content {
	height: 0;
	width: 0;
	overflow-x: hidden;
	overflow-y: hidden; }

.vjs-volume-menu-button-vertical:hover .vjs-menu-content,
.vjs-volume-menu-button-vertical:focus .vjs-menu-content,
.vjs-volume-menu-button-vertical.vjs-slider-active .vjs-menu-content,
.vjs-volume-menu-button-vertical .vjs-lock-showing .vjs-menu-content {
	height: 8em;
	width: 2.9em; }

.vjs-volume-menu-button-horizontal:hover .vjs-menu-content,
.vjs-volume-menu-button-horizontal:focus .vjs-menu-content,
.vjs-volume-menu-button-horizontal .vjs-slider-active .vjs-menu-content,
.vjs-volume-menu-button-horizontal .vjs-lock-showing .vjs-menu-content {
	height: 2.9em;
	width: 8em; }

.vjs-volume-menu-button.vjs-menu-button-inline .vjs-menu-content {
	background-color: transparent !important; }

.vjs-poster {
	position: absolute; top: 0; right: 0; bottom: 0; left: 0; height: 100%; z-index: 100;
	display: block; background: #999 center center no-repeat;
	background-size: cover;
}

.vjs-poster img {
	display: block;
	vertical-align: middle;
	margin: 0 auto;
	max-height: 100%;
	padding: 0;
	width: 100%; }

.vjs-has-started .vjs-poster {
	display: none; }

.vjs-audio.vjs-has-started .vjs-poster {
	display: block; }

.vjs-controls-disabled .vjs-poster {
	display: none; }

.vjs-using-native-controls .vjs-poster {
	display: none; }

.video-js .vjs-live-control {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: flex-start;
	-webkit-align-items: flex-start;
	-ms-flex-align: flex-start;
	align-items: flex-start;
	-webkit-box-flex: auto;
	-moz-box-flex: auto;
	-webkit-flex: auto;
	-ms-flex: auto;
	flex: auto;
	font-size: 1em;
	line-height: 3em; }

.vjs-no-flex .vjs-live-control {
	display: table-cell;
	width: auto;
	text-align: left; }

.video-js .vjs-time-control {
	-webkit-box-flex: none;
	-moz-box-flex: none;
	-webkit-flex: none;
	-ms-flex: none;
	flex: none;
	font-size: 1em;
	line-height: 3em;
	min-width: 2em;
	width: auto;
	padding-left: 1em;
	padding-right: 1em; }

.vjs-live .vjs-time-control {
	display: none; }

.video-js .vjs-current-time,
.vjs-no-flex .vjs-current-time {
	display: none; }

.video-js .vjs-duration,
.vjs-no-flex .vjs-duration {
	display: none; }

.vjs-time-divider {
	display: none;
	line-height: 3em; }

.vjs-live .vjs-time-divider {
	display: none; }

.video-js .vjs-play-control {
	cursor: pointer;
	-webkit-box-flex: none;
	-moz-box-flex: none;
	-webkit-flex: none;
	-ms-flex: none;
	flex: none; }

.vjs-tt-cue {
	display: block; }

video::-webkit-media-text-track-display {
	-moz-transform: translateY(-3em);
	-ms-transform: translateY(-3em);
	-o-transform: translateY(-3em);
	-webkit-transform: translateY(-3em);
	transform: translateY(-3em); }

.video-js.vjs-user-inactive.vjs-playing video::-webkit-media-text-track-display {
	-moz-transform: translateY(-1.5em);
	-ms-transform: translateY(-1.5em);
	-o-transform: translateY(-1.5em);
	-webkit-transform: translateY(-1.5em);
	transform: translateY(-1.5em); }

.video-js .vjs-fullscreen-control {
	cursor: pointer;
	-webkit-box-flex: none;
	-moz-box-flex: none;
	-webkit-flex: none;
	-ms-flex: none;
	flex: none; }

.vjs-playback-rate .vjs-playback-rate-value {
	font-size: 1.5em;
	line-height: 2;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center; }

.vjs-playback-rate .vjs-menu {
	width: 4em;
	left: 0em; }

.vjs-error .vjs-error-display .vjs-modal-dialog-content {
	font-size: 1.4em;
	text-align: center; }

.vjs-error .vjs-error-display:before {
	color: #fff;
	content: 'X';
	font-family: $defaultFont;
	font-size: 4em;
	left: 0;
	line-height: 1;
	margin-top: -0.5em;
	position: absolute;
	text-shadow: 0.05em 0.05em 0.1em #000;
	text-align: center;
	top: 50%;
	vertical-align: middle;
	width: 100%; }

.vjs-loading-spinner {
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -25px 0 0 -25px;
	opacity: 0.85;
	text-align: left;
	border: 6px solid rgba(43, 51, 63, 0.7);
	box-sizing: border-box;
	background-clip: padding-box;
	width: 50px;
	height: 50px;
	border-radius: 25px;
}

.vjs-seeking .vjs-loading-spinner,
.vjs-waiting .vjs-loading-spinner {
	display: block;
}

.vjs-has-started.vjs-seeking .vjs-loading-spinner,
.vjs-has-started.vjs-waiting .vjs-loading-spinner {
	display: none;
}

.vjs-loading-spinner:before,
.vjs-loading-spinner:after {
	content: "";
	position: absolute;
	margin: -6px;
	box-sizing: inherit;
	width: inherit;
	height: inherit;
	border-radius: inherit;
	opacity: 1;
	border: inherit;
	border-color: transparent;
	border-top-color: white; }

.vjs-seeking .vjs-loading-spinner:before,
.vjs-seeking .vjs-loading-spinner:after,
.vjs-waiting .vjs-loading-spinner:before,
.vjs-waiting .vjs-loading-spinner:after {
	-webkit-animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite;
	animation: vjs-spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, vjs-spinner-fade 1.1s linear infinite; }

.vjs-seeking .vjs-loading-spinner:before,
.vjs-waiting .vjs-loading-spinner:before {
	border-top-color: white; }

.vjs-seeking .vjs-loading-spinner:after,
.vjs-waiting .vjs-loading-spinner:after {
	border-top-color: white;
	-webkit-animation-delay: 0.44s;
	animation-delay: 0.44s; }

@keyframes vjs-spinner-spin {
	100% {
		transform: rotate(360deg); } }

@-webkit-keyframes vjs-spinner-spin {
	100% {
		-webkit-transform: rotate(360deg); } }

@keyframes vjs-spinner-fade {
	0% {
		border-top-color: #73859f; }
	20% {
		border-top-color: #73859f; }
	35% {
		border-top-color: white; }
	60% {
		border-top-color: #73859f; }
	100% {
		border-top-color: #73859f; } }

@-webkit-keyframes vjs-spinner-fade {
	0% {
		border-top-color: #73859f; }
	20% {
		border-top-color: #73859f; }
	35% {
		border-top-color: white; }
	60% {
		border-top-color: #73859f; }
	100% {
		border-top-color: #73859f; } }

.vjs-chapters-button .vjs-menu ul {
	width: 24em; }

.video-js.vjs-layout-tiny:not(.vjs-fullscreen) .vjs-custom-control-spacer {
	-webkit-box-flex: auto;
	-moz-box-flex: auto;
	-webkit-flex: auto;
	-ms-flex: auto;
	flex: auto; }

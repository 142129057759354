.account-nav {
  margin: 0 -20px;
  font-size: rem(16px);

  @media #{$medium} {
    margin: 0 -40px;
  }
}

.account-nav__link {
  display: flex;
  color: $black;
  text-decoration: none;
  padding: 11px 20px 11px 40px;
  font-weight: 500;
  align-items: center;
  line-height: 1.2em;
  position: relative;

  svg {
    fill: $black;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  @media #{$medium} {
    padding-left: 80px;
    padding-right: 40px;

    svg {
      left: 50px;
    }
  }
}

.account-nav__link:hover,
.account-nav__link:focus,
.account-nav__link--active {
  color: $accent;
  background-color: #f7f7f7;

  svg {
    fill: $accent;
  }
}
